import { useState, useEffect } from 'react';

export function useDefaultValue<T>(value: T, defaultValue: T) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (isMounted === true) {
    return value;
  }

  return defaultValue;
}
